<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <b-card no-body class="">
    <div class="d-flex align-items-baseline border-bottom">
      <p class="font-weight-bolder m-2">Applications</p>
      <div class="ui-spacer"></div>
      <b-button @click="$refs.privateAppManagementModal.show()" class="d-flex ml-auto btn-outline mr-2">
        Private Apps
      </b-button>
      <b-button @click="$refs.addAppModal.show()" class="d-flex ml-auto btn-success mr-2">
        Create Application
      </b-button>
    </div>

    <section class="">
      <b-table
        class="data-table"
        hover
        :items="apps"
        :fields="headers"
        :responsive="true"
      >

        <template #cell(name)="data">
          {{ data.item.name }}
          <VChip v-if="data.item.track" color="secondary" variant="outlined">
            {{ data.item.track }}
          </VChip>
        </template>

        <template #cell(roles)="data">
          <p class="d-inline-block mb-0" style="margin-right: 2px" v-for="(role, index) in data.item.roles"
             :key="role.id">
            {{ getRoleWord(data.item.roles, role.name, index) | capEachWord }}
          </p>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDateTime }}
        </template>

        <template #cell(auto_update_mode)="data">
          {{ data.item.auto_update_mode.replace('AUTO_UPDATE_', '') }}
        </template>
        <template #cell(flags)="data">
          <label class="d-inline-block" style="min-width: 55px">
            <i :class="data.item.enable_mdm_auth ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'" class="fas"></i>
            Auth
          </label>
          <label class="d-inline-block" style="min-width: 80px">
            <i :class="data.item.purple_account_enabled ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'" class="fas "></i>
            Account
          </label>
        </template>

        <template #cell(actions)="data">
          <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>

            <b-dropdown-item-button class="full-nested-btn" @click="$refs.updateAppModel.show(data.item)">
              Update App
            </b-dropdown-item-button>

            <b-dropdown-item-button class="full-nested-btn" @click="$refs.managedConfigurationsModal.show(data.item)">
              Manage Config
            </b-dropdown-item-button>

            <b-dropdown-item-button class="full-nested-btn" @click="$refs.managePermissionModal.show(data.item)">
              Manage Permissions
            </b-dropdown-item-button>

            <b-dropdown-item-button class="full-nested-btn" @click="deleteApp(data.item)">
              Delete app
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-row v-if="apps.length === 0">
        <b-col class="ml-2 mt-1">
          <p>No results</p>
        </b-col>
      </b-row>
    </section>

    <private-app-management-modal ref="privateAppManagementModal" :uid="uid" />
    <add-app-modal ref="addAppModal" :uid="uid" @refresh="getApps" />
    <update-app-modal ref="updateAppModel" :uid="uid" @refresh="getApps" />
    <managed-configurations-modal ref="managedConfigurationsModal" :uid="uid" @refresh="getApps" />
    <manage-permissions-modal ref="managePermissionModal" :uid="uid" @refresh="getApps" />

  </b-card>
</template>
<!--eslint-enable-->

<script>
import UpdateAppModal from '@/views/enterprises/viewEnterprises/sections/UpdateAppModal.vue'
import AddAppModal from '@/views/enterprises/viewEnterprises/sections/AddAppModal.vue'
import PrivateAppManagementModal from '@/views/enterprises/privateAppManagement/PrivateAppManagementModal.vue'
import EnterpriseService from '@/services/EnterpriseService'
import ManagedConfigurationsModal from '@/views/enterprises/viewEnterprises/sections/ManagedConfigurationsModal.vue'
import ManagePermissionsModal from '@/views/enterprises/viewEnterprises/sections/ManagePermissionsModal.vue';
import confirmBox from '@/views/parts/parts';
import Vue from 'vue';

export default {
  components: {
    UpdateAppModal,
    AddAppModal,
    PrivateAppManagementModal,
    ManagedConfigurationsModal,
    ManagePermissionsModal,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedApp: null,
      apps: [],
      headers: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'package_name',
          label: 'Package name',
        },
        {
          key: 'auto_update_mode',
          label: 'Auto Update',
        },
        {
          key: 'default_permission_policy',
          label: 'Permission Policy',
        },
        {
          key: 'flags',
          label: 'Flags',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
    }
  },
  mounted() {
    this.getApps()
  },
  methods: {
    async getApps() {
      try {
        const appRequest = await EnterpriseService.getAllApplications(this.uid);
        this.apps = appRequest.data;
      } catch (e) {
        Vue.$toast.error('Could not get enterprise applications, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    async deleteApp(app) {
      try {
        const result = await this.$bvModal.msgBoxConfirm(`Are you sure you wish to delete the application "${app.name}"`, confirmBox);

        if (result === true) {
          EnterpriseService.deleteApplication(this.uid, app.id);
          this.$toast.success(`Deleted app ${app.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })
          await this.getApps()
        }
      } catch (err) {
        const res = err.response
        let errorText = 'Could not delete app, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
  },
}
</script>
